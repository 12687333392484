import React, { useState } from "react";

import Image from "./Image";
import VideoModal from "./VideoModal";

import { css } from "@emotion/core";

import { Inter02, Inter01, Inter2r, Inter3b } from "../styles/blog";
import { blogMd } from "../styles/breakpoints";
import { textPrimary, white } from "../styles/colors";

const styles = {
  card: css`
    display: flex;
    width: 100%;
    text-align: left;
    margin-bottom: 40px;

    &:hover {
      background: ${white};
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06),
        0px 4px 5px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.05);
    }
  `,
  img: css`
    width: calc(50% - 12px);
    margin: 0 auto;
  `,
  author: css`
    display: block;
    margin-bottom: 4px;
    ${Inter02};
    color: ${textPrimary};

    @media (${blogMd}) {
      ${Inter2r};
    }
  `,
  title: css`
    width: 100%;
    padding-left: 12px;
    margin: 0;
    ${Inter01};

    @media (${blogMd}) {
      ${Inter3b};
      padding-left: 24px;
      padding-top: 24px;
    }
  `
};

const VideoCard = ({ image, author, content, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const modalOpen = () => setOpen(true);
  const modalClose = () => setOpen(false);

  return (
    <>
      <button css={styles.card} onClick={modalOpen}>
        <Image image={image} css={styles.img} />
        <h2 css={styles.title}>
          <b css={styles.author}>{author}</b> {content}
        </h2>
      </button>
      <VideoModal
        onRequestClose={modalClose}
        isOpen={isOpen}
        poster={image}
        {...props}
      />
    </>
  );
};

VideoCard.defaultProps = {
  author: "Auther Name",
  content: "TED’s secret to great public speaking"
};

export default VideoCard;
