import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { blogMd } from "./breakpoints";
import { Inter4, Inter3b, Inter2, Inter1, Inter01, Inter02 } from "./blog";

const figCaption = css`
  display: block;
  ${Inter02};
  text-align: center;
  margin-top: 8px;

  @media (${blogMd}) {
    ${Inter01};
  }
`;

export const markdownStyles = css`
  p,
  ul,
  ol,
  table,
  figure,
  img {
    margin-bottom: 24px;

    @media (${blogMd}) {
      margin-bottom: 32px;
    }
  }

  .gatsby-resp-image-figcaption {
    ${figCaption};
  }
`;

export const H1 = styled.h1`
  ${Inter3b};
  margin-bottom: 16px;

  @media (${blogMd}) {
    ${Inter4};
  }
`;

export const H2 = styled.h2`
  ${Inter2};
  margin-bottom: 16px;

  @media (${blogMd}) {
    ${Inter3b};
  }
`;

export const H3 = styled.h3`
  ${Inter1};
  margin-bottom: 16px;

  @media (${blogMd}) {
    ${Inter2};
  }
`;

export const Blockquote = styled.blockquote`
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  ${Inter2};
  text-align: center;
  letter-spacing: -0.5px;

  @media (${blogMd}) {
    ${Inter3b};
    letter-spacing: -0.5px;
    max-width: 75%;
  }
`;

export const UL = styled.ul`
  display: block;
  list-style-type: decimal;
`;

export const OL = styled.ol`
  display: block;
  list-style-type: decimal;
`;

export const figureImg = css`
  display: block;
  margin-bottom: 0;

  @media (${blogMd}) {
    margin-bottom: 0;
  }
`;

export const Figcaption = styled.figcaption`
  ${figCaption};
`;
