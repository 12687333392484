import React, { useState, useContext } from "react";
import { useLocation } from "@reach/router";
import { PreviewContext } from "../cms/PreviewContext";

import useSiteMetadata from "../hooks/useSiteMetadata";

import { FacebookIcon, LinkedInIcon, TwitterIcon } from "./icons";

import { css } from "@emotion/core";

import { Inter02, Inter1r } from "../styles/blog";
import { blogMd } from "../styles/breakpoints";
import { gray1, gray2, textSecondary } from "../styles/colors";

const styles = {
  box: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 40px auto 48px;

    @media (${blogMd}) {
      flex-direction: row;
    }
  `,
  title: css`
    ${Inter02};
    color: ${textSecondary};
    margin-bottom: 16px;

    @media (${blogMd}) {
      margin-bottom: 0;
      margin-right: 20px;
      ${Inter1r};
    }
  `,
  list: css`
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  `,
  item: css`
    display: flex;
    width: 38px;
    height: 38px;
    margin: 0 16px;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    @media (${blogMd}) {
      margin: 0 20px;
    }
  `,
  icon: css`
    width: 16px;
  `
};

export const ShareIcon = ({
  Icon,
  pageurl,
  popupSize,
  name,
  shareLink,
  text
}) => {
  const [active, setActive] = useState(false);
  const openPopup = e => {
    e.preventDefault();
    window.open(
      shareLink({ url: pageurl, text }),
      name,
      `${popupSize},resizable=1`
    );
  };

  return (
    <a
      href={shareLink({ url: pageurl, text })}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={`Share on ${name}`}
      css={styles.item}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={openPopup}
    >
      <Icon color={active ? gray1 : gray2} bg={active ? gray2 : gray1} />
    </a>
  );
};

const ShareBar = ({ platforms, ...shareProps }) => (
  <section css={styles.box}>
    <h2 css={styles.title}>Share</h2>
    <ul css={styles.list}>
      {platforms.map(({ name, ...icon }) => (
        <li key={name}>
          <ShareIcon name={name} {...icon} {...shareProps} />
        </li>
      ))}
    </ul>
  </section>
);

const encodeParams = params => new URLSearchParams(params).toString();

ShareBar.defaultProps = {
  pageurl: (typeof window !== "undefined" && window.location.href) || "",
  text: "",
  platforms: [
    {
      name: "Facebook",
      Icon: FacebookIcon,
      popupSize: "height=285,width=550",
      shareLink: ({ url }) =>
        `https://facebook.com/sharer/sharer.php?${encodeParams({
          u: url
        })}`
    },
    {
      name: "Twitter",
      Icon: TwitterIcon,
      popupSize: "height=285,width=550",
      shareLink: ({ url, text }) =>
        `https://twitter.com/intent/tweet/?${encodeParams({
          url,
          text
        })}`
    },
    {
      name: "LinkedIn",
      Icon: LinkedInIcon,
      popupSize: "height=500,width=500",
      shareLink: ({ url, text }) =>
        `https://www.linkedin.com/shareArticle?${encodeParams({
          mini: true,
          url,
          title: text
        })}`
    }
  ]
};

const ShareBarWithData = props => {
  const { siteUrl } = useSiteMetadata();
  const location = useLocation();
  const pageurl =
    typeof window !== "undefined"
      ? window.location.href
      : `${siteUrl}/${location.pathname}`;

  return <ShareBar pageurl={pageurl} {...props} />;
};

const ShareBarWithContext = props => {
  const previewMode = useContext(PreviewContext);

  return previewMode ? (
    <ShareBar {...props} />
  ) : (
    <ShareBarWithData {...props} />
  );
};

export default ShareBarWithContext;
