import React from "react";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer as GatsbyMDXRenderer } from "gatsby-plugin-mdx";

import * as S from "../styles/markdown.styles";
import VideoCard from "./VideoCard";

export const shortcodes = { VideoCard };

export const typography = {
  h1: S.H1,
  h2: S.H2,
  h3: S.H3,
  blockquote: S.Blockquote,
  ul: S.UL,
  ol: S.OL,
};

const MDXRenderer = ({ mdx, components }) => (
  <MDXProvider components={{ ...components, ...typography, ...shortcodes }}>
    <GatsbyMDXRenderer>{mdx.body}</GatsbyMDXRenderer>
  </MDXProvider>
);

export default MDXRenderer;
