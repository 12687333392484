import React from "react";

import Image from "./Image";

import { css } from "@emotion/core";

import { Inter02, Inter1r, Inter2, Inter3b } from "../styles/blog";
import { blogMd, blogLg } from "../styles/breakpoints";
import { gray1, textSecondary, textPrimary } from "../styles/colors";

const styles = {
  box: css`
    display: flex;
    padding-top: 40px;
    border-top: 1px solid ${gray1};
    margin-bottom: 40px;
  `,
  imgBox: css`
    width: calc(100% * 1 / 3);

    @media (${blogMd}) {
      width: 25%;
    }
  `,
  img: css`
    border-radius: 100%;
    overflow: hidden;
  `,
  content: css`
    width: calc(100% * 2 / 3);
    padding-left: 12px;

    @media (${blogMd}) {
      width: 75%;
      padding-left: 24px;
    }

    @media (${blogLg}) {
      padding-left: 32px;
    }
  `,
  title: css`
    margin-bottom: 8px;
    ${Inter2};
    color: ${textPrimary};

    @media (${blogMd}) {
      ${Inter3b};
    }
  `,
  text: css`
    margin: 0 auto;
    ${Inter02};
    color: ${textSecondary};

    @media (${blogMd}) {
      ${Inter1r};
      line-height: ${30 / 18};
    }
  `
};

const AuthorBlock = ({ image, name, bio }) => (
  <section css={styles.box}>
    <div css={styles.imgBox}>
      {image && <Image image={image} css={styles.img} square={true} />}
    </div>
    <div css={styles.content}>
      <h2 css={styles.title}>{name}</h2>
      <p css={styles.text}>{bio}</p>
    </div>
  </section>
);

export default AuthorBlock;
