import React from "react";

import { css } from "@emotion/core";

import Btn, { styles as BtnStyles } from "./Btn";

import { Inter1r, Inter2, Inter2r, Inter3b, Inter4 } from "../styles/blog";
import { blogMd } from "../styles/breakpoints";
import { white, bgDarkBlue } from "../styles/colors";

const styles = {
  box: css`
    background-color: ${bgDarkBlue};
  `,
  boxFull: css`
    margin: 48px auto;
  `,
  boxCTA: css`
    display: block;
    text-decoration: none;
  `,
  content: css`
    padding: 32px 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: ${white};
  `,
  fullWidth: css`
    padding-left: 25px;
    padding-right: 25px;
  `,
  title: css`
    ${Inter2};
    margin-bottom: 24px;

    @media (${blogMd}) {
      ${Inter3b};
    }
  `,
  titleBig: css`
    ${Inter3b};
    margin-bottom: 32px;

    @media (${blogMd}) {
      ${Inter4};
    }
  `,
  intro: css`
    ${Inter1r};
    line-height: ${30 / 18};
    margin-bottom: 16px;

    @media (${blogMd}) {
      ${Inter2r};
    }
  `,
  ctaWrap: css`
    margin: 0 auto;
  `,
  btn: css`
    ${BtnStyles.btnBlog};
    margin-bottom: 0;
  `,
};

export const HeroBoxCTA = ({ className, enabled, label, title, url }) => {
  if (!enabled) {
    return <></>;
  }

  return (
    <a className={className} css={[styles.box, styles.boxCTA]} href={url}>
      <div css={styles.content}>
        <p css={styles.title}>
          {title || "Try TED’s official public speaking course"}
        </p>
        <p css={styles.btn}>{label || "Learn more"}</p>
      </div>
    </a>
  );
};

HeroBoxCTA.defaultProps = {
  url: "#learn-more",
};

const defaultTitle = "Are you ready to start building your own TED-style talk?";
const defaultIntro = "Try TED’s official public speaking course";

export const HeroBoxFull = ({
  className,
  cta,
  enabled,
  intro: cmsIntro,
  title: cmsTitle,
}) => {
  if (!enabled) {
    return <></>;
  }

  // Provide defaults _only_ if the value is null. Other falsey values are allowed.
  const intro = cmsIntro !== null ? cmsIntro : defaultTitle;
  const title = cmsTitle !== null ? cmsTitle : defaultIntro;

  return (
    <aside className={className} css={[styles.box, styles.boxFull]}>
      <div css={[styles.content, styles.fullWidth]}>
        {intro && <p css={styles.intro}>{intro}</p>}
        {title && <p css={intro ? styles.titleBig : styles.title}>{title}</p>}
        {cta && (
          <p css={styles.ctaWrap}>
            <Btn css={styles.btn} {...cta} />
          </p>
        )}
      </div>
    </aside>
  );
};
