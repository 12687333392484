import React, { useEffect, useContext } from "react";
import ReactModal from "react-modal";

import { PreviewContext } from "../cms/PreviewContext";

import { CloseX } from "../components/icons";
import PreviewPlayer from "./PreviewPlayer";

import { ClassNames, Global, css } from "@emotion/core";
import { fadeInUp } from "../styles/animations";
import { white, black } from "../styles/colors";

const styles = {
  overlay: css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  `,
  content: css`
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
  `,
  wrapper: css`
    position: relative;
    width: 100%;
    animation-timing-function: ease-out;
    animation-duration: 0.4s;
    animation-name: ${fadeInUp};
    transform: translate(0);
    transition: transform 0.4s ease-out;
  `,
  closeBtn: css`
    position: absolute;
    top: -16px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    overflow: hidden;
    transform: translateY(-100%);
    background-color: ${white};
  `
};

const modalStyles = css`
  .css-react-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
  .css-react-modal__content {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
  }
`;

const previewParent = () => {
  const iframe = document.getElementsByTagName("iframe")[0];
  return iframe.contentDocument.body;
};

const VideoModal = ({ videourl, poster, onRequestClose, ...props }) => {
  const previewMode = useContext(PreviewContext);

  useEffect(() => {
    const appEl = document.getElementById("___gatsby") ? "#___gatsby" : "body";
    ReactModal.setAppElement(appEl);
  }, []);

  return (
    <>
      <Global styles={modalStyles} />
      <ReactModal
        shouldCloseOnOverlayClick={true}
        onRequestClose={onRequestClose}
        parentSelector={(previewMode && previewParent) || undefined}
        {...props}
        className={"css-react-modal__content"}
        overlayClassName={"css-react-modal__overlay"}
      >
        <div css={styles.wrapper}>
          <button css={styles.closeBtn} onClick={onRequestClose}>
            <CloseX width={18} height={18} color={black} />
          </button>
          <PreviewPlayer videoUrl={videourl} poster={poster} />
        </div>
      </ReactModal>
    </>
  );
};

VideoModal.defaultProps = {
  videourl: "",
  onRequestClose: () => undefined
};

export default VideoModal;
