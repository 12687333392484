import React from "react";
import { graphql } from "gatsby";

import AuthorBlock from "./AuthorBlock";
import BlogMark from "./BlogMark";
import Footer from "./Footer";
import Header from "./Header";
import Layout from "./layout";
import Image from "./Image";
import { HeroBoxCTA, HeroBoxFull } from "./HeroBox";
import MDXRenderer from "./MDXRenderer";
import ShareBar from "./ShareBar";

import { Global, css } from "@emotion/core";

import { markdownStyles } from "../styles/markdown.styles";
import { Inter01, Inter02, Inter2r, Inter3b, Inter4 } from "../styles/blog";
import { blogMd, blogLg } from "../styles/breakpoints";
import { textPrimary, textSecondary, bgLightBlue } from "../styles/colors";

const styles = {
  page: css`
    background: ${bgLightBlue};
  `,
  wrap: css`
    padding: 0 25px;
    margin: 0 auto;
    overflow: hidden;
    color: ${textPrimary};

    @media (${blogMd}) {
      padding: 0 50px;
    }

    @media (${blogLg}) {
      max-width: 900px;
    }
  `,
  content: css``,
  htmlWrap: css`
    margin-bottom: 80px;
    ${Inter01};

    @media (${blogMd}) {
      ${Inter2r};
    }
  `,
  mark: css`
    margin-bottom: 40px;
  `,
  header: css`
    margin-bottom: 40px;
    text-align: center;
  `,
  title: css`
    ${Inter3b};
    margin-bottom: 32px;

    @media (${blogMd}) {
      ${Inter4};
    }
  `,
  metadata: css`
    ${Inter02};
    color: ${textSecondary};

    @media (${blogMd}) {
      ${Inter01};
    }
  `,
  tag: css`
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,
  byline: css`
    margin-bottom: 32px;
    text-align: center;
  `,
};

const templateCTA = {
  title: "Try TED’s official public speaking course",
  label: "Learn more",
  url: "#learn-more",
};

const templateBanner = {
  enabled: true,
  intro: "Are you ready to start building your own TED-style talk?",
  title: "Try TED’s official public speaking course",
  cta: { href: "#learn-more", label: "Learn more" },
};

export const BlogPostTemplate = ({
  author,
  body,
  date,
  image,
  heroCTA,
  heroFull,
  tag,
  title,
}) => {
  const { cta: heroFullCTA, ...heroFullProps } = heroFull || {};

  return (
    <>
      <Global styles={markdownStyles} />
      <article css={styles.wrap}>
        <BlogMark css={styles.mark} link={"/blog"} />
        <header css={styles.header}>
          <h1 css={styles.title}>
            {tag && <small css={[styles.tag, styles.metadata]}>{tag}</small>}
            {title}
          </h1>
          <p css={[styles.byline, styles.metadata]}>
            {author && `by ${author.name} • `}
            {date}
          </p>
          <Image image={image} />
        </header>
        <div css={styles.htmlWrap}>{body}</div>
        <footer>
          <ShareBar text={title} />
          <HeroBoxCTA {...templateCTA} {...heroCTA} />
          {author && <AuthorBlock {...author} />}
        </footer>
      </article>
      <HeroBoxFull
        {...templateBanner}
        {...heroFullProps}
        cta={{ ...templateBanner.cta, ...heroFullCTA }}
      />
    </>
  );
};

BlogPostTemplate.defaultProps = {
  author: {
    name: "TED Talks",
    bio: "",
  },
};

const BlogPostPage = (props) => {
  const { mdx } = props.data;
  const { frontmatter } = mdx;
  const { seo } = frontmatter;

  const { title: seoTitle, desc: description } = seo || {};
  const ogImage =
    frontmatter.ogImage &&
    frontmatter.ogImage.childImageSharp &&
    frontmatter.ogImage.childImageSharp.fixed;

  const meta = {
    title: seoTitle || frontmatter.title,
    "og:title": frontmatter.title,
    description,
    ogImage: ogImage,
  };

  return (
    <Layout
      css={styles.page}
      meta={{
        meta: meta,
        titleTemplate: "%s | Blog",
      }}
    >
      <Header />
      <BlogPostTemplate {...frontmatter} body={<MDXRenderer mdx={mdx} />} />
      <Footer />
    </Layout>
  );
};

export default BlogPostPage;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        author {
          name
          bio
          image {
            childImageSharp {
              id
              fluid(maxWidth: 180, maxHeight: 180, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
        image: featuredimage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        ogImage: featuredimage {
          childImageSharp {
            fixed(width: 1200, quality: 100) {
              src
              height
              width
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        heroCTA {
          enabled
          title
          label
          url
        }
        heroFull {
          enabled
          intro
          title
          cta {
            href
            label
          }
        }
        tag
        title
        seo {
          title
          desc
        }
      }
    }
  }
`;
